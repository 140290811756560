<template>
  <div class="title-bar">
    <div class="top-part">
      <router-link to="/" @click.native="clickedHome">
        <div class="name">Cas Spoelstra</div>
      </router-link>
    </div>
    <div class="grad-divider"></div>
    <div class="line-container">
      <div class="line"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TitleBar',
  methods: {
    clickedHome: function () {
      if (this.$store.state.page.name === 'Home') {
        window.scroll({
          top: 0,
          behavior: 'smooth'
        })
      }
    }
  }
}
</script>

<style lang="scss">
  @import '@/style/vars.scss';

  .title-bar {
    position: fixed;
    background-color: $c-bg;
    height: 3.5em;
    width: 100%;
    top: 0;
    z-index: 2;
    text-align: center;
    // border-bottom: 1px solid $c-red;
  }

  .name {
    display: inline-block;
    font-size: 2em;
    font-weight: 700;
    color: $c-red;
    text-align: center;
    // vertical-align: top;
    padding: 0.3em 0 0;
    width: 100%;
  }

  .title-about {
    display: inline-block;
    vertical-align: bottom;
    text-align: right;
    position: absolute;
    right: 0.5em;
    top: 1.5em;
    color: $c-red;
  }

  a {
    text-decoration: none;
  }

  body {
    padding-top: 4.5em !important;
  }

  @media (prefers-color-scheme: dark) {
    .title-bar {
      background-color: $c-d-bg
    }
  }

</style>