import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// import VueGtag from "vue-gtag"
// import VueAnalytics from 'vue-analytics'

Vue.config.productionTip = false

// Vue.use(VueGtag, {
//   config: { id: "G-6GWMSY08WF" }
// })
// Vue.use(VueAnalytics, {
//   id: '2263861000'
// })

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
