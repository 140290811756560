<template>
  <div>
    <title-bar/>
    <transition mode="out-in" v-on:leave="leave" v-on:enter="enter">
      <router-view/>
    </transition>
  </div>
</template>

<script>
import animejs from 'animejs'
// import HelloWorld from './components/HelloWorld.vue'
import store from '@/store/index.js'
import TitleBar from '@/components/TitleBar.vue'

export default {
  name: 'App',
  store,
  components: {
    TitleBar
    // HelloWorld
  },
  methods: {
    leave: function (el, done) {
      if (store.state.page.name === 'Home') {
        const container = document.getElementsByClassName('project-page')
        animejs({
          targets: container,
          opacity: 0,
          delay: 0,
          duration: 250,
          easing: 'easeOutQuint',
          complete: () => {
            window.scrollTo(0, store.state.homeScrollPosition)
            done()
          }
        })
      } else if (store.state.page.name === 'Project') {
        if (store.state.previousPage === 'Home') {
          store.commit('updateHomeScrollPosition', window.scrollY)
        }
        // const dotContainerElement = document.getElementById(`project-id-${store.state.page.params.id}`)
        // const dotElement = dotContainerElement.getElementsByClassName('dot-behind')[0]
        // const titleElement = dotContainerElement.getElementsByClassName('dot-text-actual')[0]
        const container = document.getElementsByClassName('home')

        animejs({
          targets: container,
          opacity: 0,
          delay: 0,
          duration: 250,
          easing: 'easeOutQuint',
          complete: () => {
            window.scrollTo(0,0)
            done()
          }
        })

        // SWIPE LEFT ANIMATION
        // animejs({ // FADE
        //   targets: container,
        //   opacity: 0,
        //   duration: 300,
        //   delay: 500,
        //   easing: 'easeOutQuint',
        // })
        // animejs({ // CONTENT LEFT
        //   targets: container,
        //   translateX: '-40px',
        //   duration: 300,
        //   delay: 400,
        //   easing: 'easeInOutCirc',
        // })
        // animejs({ // DOT LEFT
        //   targets: dotElement,
        //   left: '0',
        //   duration: 650,
        //   easing: 'cubicBezier(.89,-0.78,.99,.17)',
        //   complete: () => {
        //     window.scrollTo(0,0)
        //     done()
        //   }
        // })
      }
    },
    enter: function (el, done) {
      if (store.state.page.name === 'Project') {
        if (store.state.previousPage === 'Home') {
          // const dotElement = document.getElementsByClassName('dot-label')[0].getElementsByClassName('dot-behind')[0]
          // const titleElement = document.getElementsByClassName('dot-label')[0].getElementsByClassName('dot-text-actual')[0]
          const contentContainer = document.querySelector('.content-container')
          const titleContainer = document.querySelector('.dot-label')
          const titleSubContainer = document.querySelector('.project-sub')
          contentContainer.style.opacity = 0
          titleContainer.style.opacity = 0
          titleSubContainer.style.opacity = 0

          animejs({
          targets: [contentContainer, titleContainer, titleSubContainer],
          opacity: 1,
          delay: 0,
          duration: 250,
          easing: 'easeOutQuint',
          complete: () => {
            done()
          }
        })

          // SWIPE LEFT ANIMATION
          // titleElement.style.opacity = 0
          // animejs({
          //   targets: [titleContainer, titleSubContainer],
          //   opacity: 1,
          //   delay: 200,
          //   duration: 500,
          //   easing: 'easeOutExpo',
          // })
          // animejs({
          //   targets: contentContainer,
          //   opacity: 1,
          //   translateX: ['40px', '0px'],
          //   delay: 200,
          //   duration: 450,
          //   easing: 'easeOutQuint',
          // })
          // animejs({
          //   targets: [titleContainer, titleSubContainer],
          //   translateX: ['40px', '0px'],
          //   delay: 100,
          //   duration: 350,
          //   easing: 'easeOutQuint',
          // })
          // animejs({
          //   targets: dotElement,
          //   left: ['120%', '50%'],
          //   delay: 100,
          //   duration: 250,
          //   easing: 'easeOutQuint',
          //   complete: () => {
          //     done()
          //   }
          // })
        }
      } else if (store.state.previousPage === 'Project') {
        const container = document.getElementsByClassName('home')[0]
        container.style.opacity = 0
        animejs({
          targets: container,
          opacity: 1,
          delay: 0,
          duration: 250,
          easing: 'easeOutQuint',
          complete: () => {
            done()
          }
        })
      }
      // setTimeout(() => {
      //   console.log('enter')
      //   done()
      // }, 2000)
    }
  }

}
</script>

<style lang="scss">
@import url('../node_modules/normalize.css/normalize.css');
@import '@/style/vars.scss';
@import '@/style/global.scss';


#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
