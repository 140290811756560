import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    filter: {
      id: true,
      dev: true
    },
    previousPage: null,
    page: {
      name: 'Home',
      params: null
    },
    homeScrollPosition: 0,
    about: {
      introEnabled: true,
      popupEnabled: false
    }
  },
  mutations: {
    updatePage (state, toPage) {
      // console.log(toPage)
      // console.log(params)
      state.previousPage = state.page.name
      state.page = {
        name: toPage.name,
        params: toPage.params
      }
    },
    toggleAbout (state, payload) {
      if (payload.type === 'intro') {
        state.about.introEnabled = payload.toggle;
      } else if (payload.type === 'popup') {
        state.about.popupEnabled = payload.toggle;
      }
    },
    updateHomeScrollPosition (state, payload) {
      state.homeScrollPosition = payload
    },
    toggleFilter (state, payload) {
      if (payload === 'dev') {
        state.filter.dev = !state.filter.dev
      } else if (payload === 'id') {
        state.filter.id = !state.filter.id
      } else {
        return new Error('Incorrect payload')
      }
    }
  },
  actions: {
  },
  modules: {
  }
})
