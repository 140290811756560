import Vue from 'vue'
import VueRouter from 'vue-router'
const Home = () => import('../views/Home.vue')
const Project = () => import('../views/Project.vue')
// import PageNotFound from '../views/PageNotFound.vue'
import pages from './middleware/pages'
import store from '../store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/project/:projectSlug',
    name: 'Project',
    component: Project
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  pages(store, to)
  return next()
//   // console.log(this)
//   // return true
})

export default router
